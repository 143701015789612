import { acceptHMRUpdate, defineStore } from 'pinia'
import GET_UNFINISHED_TASKS from '~/graphql/queries/numberOfUnfinishedTasks.gql'
import SUBSCRIBE_UNFINISHED_TASKS from '~/graphql/subscriptions/numberOfUnfinishedTasks.gql'

enum UnfinishedTasks {
  OPEN_REVISIONS = 'OPEN_REVISIONS',
  REVISION_INCOMPATIBILITIES = 'REVISION_INCOMPATIBILITIES',
}

export const useUnfinishedTasksStore = defineStore('unfinishedTasks', () => {
  const { result, subscribeToMore } = useQuery(GET_UNFINISHED_TASKS, null, { fetchPolicy: 'network-only' })
  subscribeToMore(() => ({
    document: SUBSCRIBE_UNFINISHED_TASKS,
    updateQuery: (previousResult, { subscriptionData }) => {
      const newTask = subscriptionData.data.numberOfUnfinishedTasks
      const newTasks = []
      for (const oldTask of previousResult.numberOfUnfinishedTasks) {
        if (oldTask.key === newTask.key)
          newTasks.push(newTask)
        else
          newTasks.push(oldTask)
      }
      return { numberOfUnfinishedTasks: newTasks }
    },
  }))
  const numberOfUnfinishedTasks = computed<{ key: string, quantity: number }[] | undefined>(() => {
    return result.value?.numberOfUnfinishedTasks
  })

  const openRevisions = computed(() => {
    return numberOfUnfinishedTasks.value?.find(({ key }) => key === UnfinishedTasks.OPEN_REVISIONS)?.quantity
  })
  const revisionIncompatibilities = computed(() => {
    return numberOfUnfinishedTasks.value?.find(({ key }) => key === UnfinishedTasks.REVISION_INCOMPATIBILITIES)?.quantity
  })
  const totalUnfinishedTasks = computed(() => {
    return (openRevisions.value ?? 0) + (revisionIncompatibilities.value ?? 0)
  })

  return {
    openRevisions,
    revisionIncompatibilities,
    totalUnfinishedTasks,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUnfinishedTasksStore, import.meta.hot))
